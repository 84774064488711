import { render, staticRenderFns } from "./PcEmailIcon.vue?vue&type=template&id=dbe85718&scoped=true"
import script from "./PcEmailIcon.vue?vue&type=script&lang=js"
export * from "./PcEmailIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbe85718",
  null
  
)

export default component.exports