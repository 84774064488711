import { render, staticRenderFns } from "./PcModalReskinned.vue?vue&type=template&id=1f8e677e"
import script from "./PcModalReskinned.vue?vue&type=script&lang=js"
export * from "./PcModalReskinned.vue?vue&type=script&lang=js"
import style0 from "./PcModalReskinned.vue?vue&type=style&index=0&id=1f8e677e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VDialog,VSpacer})
