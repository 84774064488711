import { render, staticRenderFns } from "./PcTooltip.vue?vue&type=template&id=4a179ac8&scoped=true"
import script from "./PcTooltip.vue?vue&type=script&lang=js"
export * from "./PcTooltip.vue?vue&type=script&lang=js"
import style0 from "./PcTooltip.vue?vue&type=style&index=0&id=4a179ac8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a179ac8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
