import { render, staticRenderFns } from "./PcToggle.vue?vue&type=template&id=44e7e3be&scoped=true"
import script from "./PcToggle.vue?vue&type=script&lang=js"
export * from "./PcToggle.vue?vue&type=script&lang=js"
import style0 from "./PcToggle.vue?vue&type=style&index=0&id=44e7e3be&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44e7e3be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
installComponents(component, {VBtn,VBtnToggle})
