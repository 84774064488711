import { render, staticRenderFns } from "./PcAlertReskinned.vue?vue&type=template&id=36ca5022&scoped=true"
import script from "./PcAlertReskinned.vue?vue&type=script&lang=js"
export * from "./PcAlertReskinned.vue?vue&type=script&lang=js"
import style0 from "./PcAlertReskinned.vue?vue&type=style&index=0&id=36ca5022&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ca5022",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
